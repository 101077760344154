<template>
  <v-card class="rounded-card2" v-if="plan.name">
    <v-card-title class="text-subtitle-1"> Plan Seleccionado </v-card-title>
    <v-card-text class="mt-3">
      <v-row class="text-h5 text--tway-violet font-weight-bold px-4">
        <div class="text-capitalize">
          {{ plan.name.toLowerCase() }}
        </div>
      </v-row>
      <v-row>
        <span class="text-subtitle text--tway-violet font-weight-bold pb-5 px-4 roboto">
          {{ plan.size.period == "ONCE" ? "Paga una sola vez" : "Suscripción anual" }}
        </span>
      </v-row>

      <v-divider class="my-2"></v-divider>

      <v-row class="mt-4 px-4">
        <div class="text--primary">
          <div
            style="display: flex; flex-direction: row; justify-content: flex-start; align-items: flex-start"
            class="mb-2"
            v-for="(feature, index) in plan.features"
            :key="index"
          >
            <v-img
              :src="require('@/assets/img/check-2.svg')"
              class="rounded mr-2"
              min-width="12px"
              max-width="12px"
              style="align-self: center"
            />
            <span v-html="feature.description" class="text-t roboto pr-2" />
          </div>
        </div>
      </v-row>

      <v-row class="mt-6 mb-5 px-4" justify="center">
        <a
          @click="
            () => {
              $router.go(-1);
            }
          "
          class="select-plan roboto"
          >Seleccionar otro Plan</a
        >
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    plan: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style></style>
