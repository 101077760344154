<template>
  <v-card class="rounded-card2">
    <v-card-title class="text-subtitle-1"> Medio de pago </v-card-title>
    <v-card-text>
      <v-radio-group column v-model="selectedPaymentMethod">
        <v-radio
          color="tway-violet"
          class="text-subtitle-1 roboto mb-5"
          value="WEBPAY_PLUS_NORMAL"
          v-if="company.countryCode == 'CL'"
        >
          <template v-slot:label>
            <v-img
              :src="require(`@/assets/img/1.WebpayPlus_FB_300px 1.png`)"
              contain
              max-height="100"
              max-width="150"
            />
          </template>
        </v-radio>
        <v-radio
          color="tway-violet"
          class="text-subtitle-1 roboto"
          value="WEBPAY_ONE_CLICK"
          disabled
          style="display: none"
        >
          <template v-slot:label>
            <v-img :src="require(`@/assets/img/oneclick.png`)" contain max-height="100" max-width="150" />
            <div class="text-subtitle-3 roboto mt-3 ml-1" :style="mobile ? 'font-size:10px' : 'font-size:16px'">
              (Próximamente)
            </div>
          </template>
        </v-radio>
        <v-radio color="tway-violet" class="text-subtitle-1 roboto" value="PAYPAL">
          <template v-slot:label>
            <v-img :src="require(`@/assets/img/paypal.png`)" contain max-height="100" max-width="150" />
          </template>
        </v-radio>
        <v-radio color="tway-violet" class="text-subtitle-1 roboto" value="PAYU" v-if="company.countryCode == 'CO'">
          <template v-slot:label>
            <v-img :src="require(`@/assets/img/payu.svg`)" contain max-height="33" max-width="79" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedPaymentMethod: "",
    };
  },
  methods: {
    ...mapActions("subscription", ["setSelectedPaymentMethod"]),
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
  watch: {
    selectedPaymentMethod(value) {
      this.setSelectedPaymentMethod(value);
    },
  },
};
</script>

<style></style>
