<template>
  <div>
    <Loader :visible="isLoading" />
    <v-form v-if="!isLoading">
      <div class="t-tway">Suscripción y Pago</div>
      <v-container fluid>
        <v-row class="ma-0">
          <v-col class="ma-0" cols="12" md="4">
            <selected-plan :plan="getSelectedPlan" />
          </v-col>
          <v-col class="ma-0" cols="12" md="4">
            <payment-method :company="getCompany" />
          </v-col>
          <v-col class="ma-0" cols="12" md="4">
            <resume
              :plan="getSelectedPlan"
              :payment-method="getSelectedPaymentMethod"
              :subscription-period="getSelectedPlan.size.period"
              :paypal-subscription-options="paypalOptions"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";
import selectedPlan from "../components/subscription/selectedPlan.vue";
import paymentMethod from "../components/subscription/paymentMethod.vue";
import resume from "../components/subscription/resume.vue";

export default {
  name: "Subscribe",
  components: {
    selectedPlan,
    paymentMethod,
    resume,
    Loader,
  },

  data: () => ({
    isLoading: true,
    totalPrice: {},
    paypalOptions: {},
  }),
  created() {
    Promise.all([
      this.setCompanyProfile(),
      this.setUmapiUserData(),
      this.setSubscription(),
      this.setSelectedPaymentMethod(""),
    ])
      .then(async () => {
        await this.setSelectedPlan({
          planId: this.$route.params.plan,
          companyType: this.getCompanyType,
        });
      })
      .finally(() => {
        this.paypalOptions = {
          planId: this.getSelectedPlan.id,
          period: this.getSelectedPlan.size.period,
          paymentMethod: this.getSelectedPlan.size.mode == "UNIQUE" ? "PAYPAL_CHECKOUT" : "PAYPAL_SUBSCRIPTION",
        };
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions("subscription", ["setSubscription", "setSelectedPlan", "setSelectedPaymentMethod"]),
    ...mapActions("CompanyStore", ["setCompanyProfile"]),
    ...mapActions("UserStore", ["setUmapiUserData"]),
  },
  computed: {
    ...mapGetters("subscription", ["getSelectedPlan", "getSelectedPaymentMethod"]),
    ...mapGetters("CompanyStore", ["getCompany", "getCompanyType"]),
    ...mapGetters("UserStore", ["getUmapiUser"]),
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style lang="scss">
.list-item-icon {
  margin-right: 0px !important;
}

.selected-payment-method-check {
  position: absolute;
}

.payment-selector {
  cursor: pointer;
  padding: 20px;
  display: flex;
}

.payment-input-selector {
  display: none;
}

.selected-billing-method {
  // border-color: rgba(0, 0, 0, 0.6)!important;
  border-color: $tway-apple-green !important;
  border-width: 2px;
}
.t-tway {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
}
.tway-title {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
}
.black-text {
  color: #333333;
}
.violet-text {
  color: #7319d5 !important;
}
.border {
  border-bottom: 1px solid #333333;
}
.text-modal {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
}
.select-plan {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #7319d5 !important;
  text-decoration: underline;
}
.card-promo {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #333333 !important;
}
.rounded-card {
  border-radius: 40px !important;
}

.rounded-card2 {
  border-radius: 10px !important;
}
.rounded-card-promo {
  border-radius: 20px !important;
  border: 1px solid #7319d5 !important;
}
.summary-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-size: 24px;
  line-height: 20px;
}
</style>
