<template>
  <div class="paypal-container">
    <v-card class="pa-4 rounded-card2">
      <v-card-text>
        <v-row justify="space-between" class="mt-10 mb-10">
          <div class="summary-text black-text font-weight-bold">
            Monto a pagar por la suscripci&oacute;n:
            <span> {{ totalprice | currency }} USD</span>
          </div>
        </v-row>
        <v-card-actions>
          <v-row class="px-0" :class="subscription.paymentMethod === 'PAYPAL_CHECKOUT' ? 'pt-4' : 'mt-8'">
            <div
              style="width: 100%"
              v-show="subscription.paymentMethod === 'PAYPAL_CHECKOUT' && accepttermsandconditions && loaded"
              ref="paypal"
            />
          </v-row>
        </v-card-actions>
        <v-row v-if="!loaded" justify="center" class="mb-4">
          <v-progress-circular indeterminate color="tway-violet" />
        </v-row>
        <v-row justify="center" class="mt-10 mb-2">
          <a @click="goBack()" class="tway-violet--text"> volver </a>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "PaypalCheckout",
  components: {},
  props: {
    subscription: {
      type: Object,
      default: () => {},
    },
    accepttermsandconditions: {
      type: Boolean,
      default: false,
    },
    totalprice: {
      type: Number,
      default: 0,
    },
    paypalclientid: {
      type: String,
      default: "",
    },
    paypalplanid: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      loaded: false,
    };
  },
  created() {
    this.loaded = false;
    this.dataChange();
  },
  watch: {
    paypalclientid() {
      this.dataChange();
    },
    paypalplanid() {
      this.dataChange();
    },
  },
  methods: {
    ...mapActions("subscription", ["setPaypalCheckoutData"]),
    goBack() {
      // emit
      this.$emit("close");
    },
    dataChange() {
      setTimeout(() => {
        var prior_script = document.getElementById("paypal-script-only");
        if (prior_script) {
          prior_script.parentNode.removeChild(prior_script);
        }
        const script = document.createElement("script");
        script.setAttribute("id", "paypal-script-only");
        script.src = "https://www.paypal.com/sdk/js?client-id=" + this.paypalclientid;
        script.addEventListener("load", this.setLoaded);
        document.body.appendChild(script);
      }, 500);
    },
    setLoaded() {
      this.loaded = true;

      window.paypal
        .Buttons({
          style: {
            shape: "pill",
            color: "blue",
            layout: "vertical",
            label: "paypal",
          },
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: this.totalprice,
                    currency_code: "USD",
                  },
                },
              ],
            });
          },
          //data, actions
          onApprove: (data, actions) => {
            actions.order.capture().then((orderData) => {
              console.log("Capture result", orderData, JSON.stringify(orderData, null, 2));

              this.isLoading = true;
              this.setPaypalCheckoutData(data);
              this.$router.push({
                name: "success-payment",
                query: { paymentMethod: "PAYPAL_CHECKOUT" },
              });
            });
          },

          onError: (err) => {
            this.$log.error("Error on payment with paypal - ", err);
            this.$router.push({ name: "error-payment" });
          },
        })
        .render(this.$refs.paypal);
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style lang="scss">
.paypal-container {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 100;
}
</style>
