<template>
  <div>
    <v-card class="pa-4 rounded-card2" v-if="plan.name">
      <v-card-text>
        <v-row class="tway-title black-text mb-6" style="font-size: 25px" justify="start">
          <div>Resumen</div>
        </v-row>
        <v-row justify="space-between" class="mb-5">
          <div class="roboto black-text" style="font-size: 16px">Plan seleccionado</div>
          <div class="roboto black-text text-capitalize" style="font-size: 16px">
            {{ plan.name.toLowerCase() }}
          </div>
        </v-row>
        <v-row justify="space-between" class="mb-5">
          <div class="roboto black-text" style="font-size: 16px">Medio de pago</div>
          <div class="roboto black-text" style="font-size: 16px">
            {{
              paymentMethod === "WEBPAY_PLUS_NORMAL"
                ? "WebPay"
                : paymentMethod === "PAYPAL"
                ? "PayPal"
                : paymentMethod === "PAYU"
                ? "PayU"
                : ""
            }}
          </div>
        </v-row>
        <v-form v-model="discountForm">
          <v-row class="mb-5">
            <v-col class="px-0" cols="7">
              <v-text-field
                :disabled="discountedPrice > 0"
                label="Código de descuento"
                v-model="discountCode"
                full-width
                outlined
                @keydown="onChangeDiscountCode"
                :rules="[required('Código de descuento'), !invalidCode || `El código de descuento no es válido`]"
                dense
                hide-details="auto"
                :id="invalidCode ? 'colorError' : ''"
                color="#7319D5"
              ></v-text-field>
            </v-col>
            <v-col cols="5" align="right" style="margin-right: 0px" v-if="discountedPrice === 0">
              <v-btn
                :loading="isLoadingDiscount"
                color="#7319D5"
                style="
                  color: white;
                  font-family: 'Roboto Condensed'
                  font-style: normal;
                "
                @click="submitTicket"
                :disabled="isLoadingDiscount"
                >Aplicar</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
        <v-row justify="space-between" class="mb-5">
          <div class="roboto font-weight-black" style="font-size: 16px">Sub Total</div>
          <div class="roboto font-weight-black text-capitalize" style="font-size: 16px">
            ${{ plan.name !== "MANAGER" ? Math.round(originalPrice) : originalPrice }}
          </div>
        </v-row>
        <v-row justify="space-between" class="mb-5" v-if="plan.size.period !== 'ONCE'">
          <div class="roboto black-text" style="font-size: 16px">Cobro mensual</div>
          <div class="roboto black-text text-capitalize" style="font-size: 16px">
            ${{ plan.name !== "MANAGER" ? Math.round(plan.size.price) : plan.size.price }}
          </div>
        </v-row>
        <v-row justify="space-between" class="mb-5" v-if="discountedPrice > 0">
          <div class="roboto font-weight-black" style="font-size: 16px; color: #7319d5">
            Descuento -{{ discountPercentage }}%
          </div>
          <div class="roboto font-weight-black text-capitalize" style="font-size: 16px; color: #7319d5">
            -${{ plan.name !== "MANAGER" ? Math.round(discountedPrice) : discountedPrice }}
          </div>
        </v-row>
        <v-divider />
        <v-row class="mt-5" justify="space-between">
          <div class="summary-text black-text font-weight-bold">Total a pagar:</div>
          <div class="summary-text black-text font-weight-bold">{{ showTotalPrice }} USD</div>
        </v-row>
        <v-row class="mt-8" justify="center">
          <v-checkbox color="success" v-model="acceptTermsAndConditions">
            <template v-slot:label>
              <div>
                <a target="_blank" @click.stop="modalTerms = true" class="violet-text">
                  Acepto los términos y condiciones de uso
                </a>
              </div>
            </template>
          </v-checkbox>
        </v-row>

        <v-dialog class="modal-dialog modal-sm" v-model="modalTerms" :max-width="desktop ? '714' : '343'">
          <v-card>
            <div class="text-h6 ml- pa-5">Términos y condiciones de uso</div>

            <v-card-text>
              <iframe
                class="iframe"
                src="https://static-dev.twaydigital.com/terms/app/TerminosCondiciones.pdf"
                :height="desktop ? '350' : '400'"
                :width="desktop ? '670' : '300'"
              />
            </v-card-text>

            <v-card-actions>
              <v-row justify="center" class="ma-0 pa-0">
                <a class="tway-violet--text ma-2 pa-2 pr-15 roboto" v-if="desktop" @click.stop="modalTerms = false">
                  VOLVER
                </a>
                <v-btn
                  v-if="desktop"
                  large
                  rounded
                  color="tway-violet"
                  class="white--text ma-2 roboto"
                  @click.stop="modalTerms = false"
                >
                  ACEPTAR
                </v-btn>
              </v-row>
              <v-row v-if="!desktop" justify="center">
                <v-row justify="center" class="ma-0 pa-0">
                  <a class="tway-violet--text pa-3 pr-5 roboto" @click.stop="modalTerms = false"> VOLVER </a>
                  <v-btn
                    x-large
                    rounded
                    color="tway-violet"
                    class="white--text roboto"
                    @click.stop="modalTerms = false"
                  >
                    ACEPTAR
                  </v-btn>
                </v-row>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card-actions>
          <v-row class="mt-2">
            <v-btn
              rounded
              color="tway-apple-green"
              block
              class="white--text roboto"
              @click="submitSubscription"
              :disabled="!canSubmit"
              :loading="isLoading"
            >
              PAGAR
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <transbank-form :webpay-plus="true" :ready="transactionReady" :url="url" :token="token" />
    <paypal-form
      v-if="paypalSubscriptionReady"
      :subscription="paypalSubscriptionOptions"
      :accepttermsandconditions="acceptTermsAndConditions"
      :totalprice="price"
      :paypalclientid="paypalclientid"
      :paypalplanid="paypalplanid"
      :product="product"
      @close="
        () => {
          paypalSubscriptionReady = false;
        }
      "
    />
    <paypalcheckout-form
      v-if="paypalCheckoutReady"
      :subscription="paypalSubscriptionOptions"
      :accepttermsandconditions="acceptTermsAndConditions"
      :totalprice="price"
      :paypalclientid="paypalclientid"
      :paypalplanid="paypalplanid"
      @close="
        () => {
          paypalCheckoutReady = false;
        }
      "
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ValidatorsService from "@/services/validatorsService";
import subscriptionService from "@/services/subscriptionService";
import PaypalSubscription from "@/views/Subscription/PaypalSubscription";
import PaypalCheckout from "@/views/Subscription/PaypalCheckout";
import oneclickService from "@/services/billing/oneclickService";

export default {
  components: {
    "paypal-form": PaypalSubscription,
    "paypalcheckout-form": PaypalCheckout,
  },
  props: {
    plan: {
      type: Object,
      default: () => {},
    },
    paypalSubscriptionOptions: {
      type: Object,
      default: () => {},
    },
    paymentMethod: {
      type: String,
      default: "",
    },
    subscriptionPeriod: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      isLoadingDiscount: false,
      enablePayBtn: true,
      discountForm: true,
      discountedPrice: 0,
      discountPercentage: "",
      price: 0,
      originalPrice: this.plan.size.totalPrice,
      discountCode: "",
      acceptTermsAndConditions: false,
      modalTerms: false,
      transactionReady: false,
      paypalSubscriptionReady: false,
      paypalCheckoutReady: false,
      url: "",
      token: "",
      invalidCode: false,
      // oneclick enroll cards
      loadingEnrollCard: false,
      selectedEnrolledCard: null,
      oneClickEnrolledCards: [],
      loadingEnrolledCards: false,
      // webpayplus
      loadingWebpayPlus: false,
      loadedPaypal: false,
      paidFor: false,
      product: {
        price: 777,
        description: "description",
        img: "./assets/img/Tway-logotipo-gris.png",
      },
    };
  },
  computed: {
    ...mapGetters("subscription", ["getSelectedPaymentMethod"]),
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
    canSubmit: function () {
      if (!this.acceptTermsAndConditions) {
        return false;
      }
      if (!this.getSelectedPaymentMethod) {
        return false;
      }
      if (this.discountForm && this.discountPercentage == "" && this.discountedPrice == 0) {
        return false;
      }
      return true;
    },
    showTotalPrice() {
      let tempPrice = this.plan.size.price;
      let tempTotalPrice = this.plan.size.totalPrice;

      if (this.plan.size.period === "YEARLY" && this.discountedPrice === 0) {
        return this.haveDecimals(tempPrice) ? tempPrice : Math.round(tempPrice);
      } else {
        return this.haveDecimals(tempTotalPrice) ? tempTotalPrice : Math.round(tempTotalPrice);
      }
    },
  },
  methods: {
    ...mapActions("subscription", ["SET_SELECTED_PLAN"]),
    required: (propertyType) => ValidatorsService.required(propertyType),
    discountError: () => !this.invalidCode || `El campo debe ser mayor a 6`,
    webpayInit() {
      this.isLoading = true;
      let discount = this.discountCode === "" ? null : this.discountCode;
      let Data = {
        discountCode: discount,
        period: this.subscriptionPeriod,
        paymentMethod: this.getSelectedPaymentMethod,
        clientId: localStorage.getItem("userId"),
        planId: this.$route.params.plan,
        sessionId: localStorage.getItem("accessToken"),
      };

      if (this.getSelectedPaymentMethod === "WEBPAY_PLUS_NORMAL") {
        subscriptionService
          .subscribe(Data)
          .then((response) => {
            if (response.paymentMethodInfo.meta) {
              this.url = response.paymentMethodInfo.meta.url;
              this.token = response.paymentMethodInfo.meta.token;
              this.transactionReady = true;
            }
          })
          .catch((err) => this.$log.error(err))
          .finally(() => (this.isLoading = false));
      }
    },
    payUInit() {
      this.isLoading = true;
      let discount = this.discountCode === "" ? null : this.discountCode;
      let data = {
        discountCode: discount,
        period: this.subscriptionPeriod,
        paymentMethod: this.plan.size.mode == "UNIQUE" ? "PAYU_SINGLE_PAY" : "PAYU_SUBSCRIPTION",
        clientId: localStorage.getItem("userId"),
        planId: this.$route.params.plan,
        sessionId: localStorage.getItem("accessToken"),
      };
      subscriptionService
        .subscribe(data)
        .then((response) => {
          let params = {
            ...response,
            planName: this.plan.name,
            price: response.price,
            paymentType: this.plan.size.period,
          };
          this.$router.push({ name: "payu", params });
        })
        .catch((err) => this.$log.error(err))
        .finally(() => (this.isLoading = false));
    },
    paypalInit() {
      this.isLoading = true;
      let discount = this.discountCode === "" ? null : this.discountCode;
      let data = {
        discountCode: discount,
        period: this.subscriptionPeriod,
        paymentMethod: this.paypalSubscriptionOptions.paymentMethod,
        clientId: localStorage.getItem("userId"),
        planId: this.$route.params.plan,
        sessionId: localStorage.getItem("accessToken"),
      };
      subscriptionService
        .subscribe(data)
        .then((response) => {
          if (response.paymentMethodInfo.meta) {
            this.paypalclientid = response.paymentMethodInfo.meta.paypalClientID;
            this.paypalplanid = response.paymentMethodInfo.meta.paypalPlanId;
            this.price = response.price;
            response.paymentMethod == "PAYPAL_SUBSCRIPTION"
              ? (this.paypalSubscriptionReady = true)
              : (this.paypalCheckoutReady = true);
          }
        })
        .catch((err) => this.$log.error(err))
        .finally(() => (this.isLoading = false));
    },
    submitSubscription() {
      this.isLoading = true;
      if (this.getSelectedPaymentMethod === "WEBPAY_PLUS_NORMAL") {
        this.webpayInit();
      }
      if (this.getSelectedPaymentMethod === "PAYPAL") {
        this.paypalInit();
      }
      if (this.getSelectedPaymentMethod === "PAYU") {
        this.payUInit();
      }
    },
    onChangeDiscountCode() {
      this.invalidCode = false;
    },
    haveDecimals(value) {
      return value % 1 !== 0 ? true : false;
    },
    submitTicket() {
      let { period } = this.plan.size;

      this.isLoadingDiscount = true;
      let price = period === "YEARLY" ? this.plan.size.price : this.originalPrice;
      subscriptionService
        .validateAndApply(this.discountCode, price, localStorage.getItem("userId"))
        .then((response) => {
          if (response.data.valid) {
            let tempPlan = this.plan;
            if (period === "YEARLY") {
              this.discountedPrice = price - response.data.finalPrice;
              tempPlan.size.price = price;
              this.discountPercentage = response.data.amountDiscount;
              tempPlan.size.totalPrice = price - this.discountedPrice;
            } else {
              tempPlan.size.price = this.originalPrice;
              tempPlan.size.totalPrice = response.data.finalPrice;
              this.discountedPrice = response.data.originalPrice - response.data.finalPrice;
              this.discountPercentage = response.data.amountDiscount;
            }
            if (this.haveDecimals(tempPlan.size.totalPrice)) {
              tempPlan.size.totalPrice = tempPlan.size.totalPrice.toFixed(2);
            }
            if (this.haveDecimals(tempPlan.size.price)) {
              tempPlan.size.price = tempPlan.size.price.toFixed(2);
            }
            if (this.haveDecimals(this.discountedPrice)) {
              this.discountedPrice = this.discountedPrice.toFixed(2);
            }
            this.SET_SELECTED_PLAN(tempPlan);
            this.isLoadingDiscount = false;
          } else {
            this.invalidCode = true;
            this.discountCode = "";
            this.isLoadingDiscount = false;
          }
        })
        .catch((err) => {
          console.error(err);
          this.invalidCode = true;
          this.discountCode = "";
          this.isLoadingDiscount = false;
        });
    },
    getOneClickEnrolledCard: function () {
      this.loadingEnrolledCards = true;
      oneclickService
        .getEnrolledCards()
        .then((cards) => {
          this.oneClickEnrolledCards = cards;
          this.selectedEnrolledCard = cards.length > 0 ? cards[0] : null;
        })
        .catch((err) => this.$log.error("Unexpected error: ", err))
        .finally(() => (this.loadingEnrolledCards = false));
    },

    enrollCardOnOneclick: function () {
      this.loadingEnrollCard = true;
      oneclickService
        .enrollCard()
        .then((url) => {
          this.loadingEnrollCard = false;

          // Como evitar bloqueo de popup?
          let enrollWindow = window.open(url, "_blank", "width=500, height=600, top=100");
          enrollWindow.onclose = () => this.getOneClickEnrolledCard();
        })
        .catch((err) => {
          this.$log.error("Unexpected error: ", err);
          this.loadingEnrollCard = false;
        });
    },
  },
};
</script>

<style>
.v-messages__message {
  font-size: 16px !important;
  font-family: "Roboto Condensed", sans-serif !important;
  color: red !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0.5rem;
  margin-bottom: 8px;
}
#colorError {
  color: red;
}
</style>
