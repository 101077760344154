import Vue from "vue";
import uaaAxios from "@/utils/authAxios";
import qs from "qs";

const TBK_ONECLICK_API = `http://localhost:7501${process.env.VUE_APP_TBK_API}/oneclick`;

const TO_TRANSBANK_URL = `${process.env.VUE_APP_BASE_URL}/billing/to-transbank?`;

export default {
  // =================================+
  // Inscriptions
  // =================================+

  // findByUserId
  getEnrolledCards: function () {
    let userId = localStorage.getItem("userId");

    return new Promise((resolve, reject) => {
      uaaAxios
        .get(`${TBK_ONECLICK_API}/users/${userId}/inscriptions`)
        .then((response) => resolve(Vue._.get(response, "data")))
        .catch((err) => reject(err));
    });
  },

  // createInscription
  enrollCard: function (inscription) {
    let userId = localStorage.getItem("userId");
    let userName = localStorage.getItem("userName");

    const defaultInscription = {
      userId: userId,
      email: userName,
      username: userName,
      successUrl: `${process.env.VUE_APP_BASE_URL}/billing/oneclick/enroll/success`,
      failureUrl: `${process.env.VUE_APP_BASE_URL}/billing/oneclick/enroll/error`,
    };

    const inscriptionRequest = Object.assign(defaultInscription, inscription);

    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${TBK_ONECLICK_API}/inscription-requests`, inscriptionRequest)
        .then((response) => {
          let formData = Vue._.get(response, "data");

          if (Vue._.get(formData, "urlWebpay") === undefined || Vue._.get(formData, "token") === undefined) {
            reject("No ha sido posible generar la url de inscripción a Transbank.");
          }

          let url =
            TO_TRANSBANK_URL +
            qs.stringify({
              action: formData.urlWebpay,
              tokenName: "TBK_TOKEN",
              tokenValue: formData.token,
            });

          resolve(url);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // =================================+
  // Transactions
  // =================================+

  // findById
  getTransaction: function (tramsactionId) {
    let userId = localStorage.getItem("userId");

    return new Promise((resolve, reject) => {
      uaaAxios
        .get(`${TBK_ONECLICK_API}/users/${userId}/transactions/${tramsactionId}`)
        .then((response) => resolve(Vue._.get(response, "data")))
        .catch((err) => reject(err));
    });
  },

  // findAll
  getTransactions: function () {
    let userId = localStorage.getItem("userId");

    return new Promise((resolve, reject) => {
      uaaAxios
        .get(`${TBK_ONECLICK_API}/users/${userId}/transactions`)
        .then((response) => resolve(Vue._.get(response, "data")))
        .catch((err) => reject(err));
    });
  },

  // createTransaction
  pay: function (data) {
    let userId = localStorage.getItem("userId");

    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${TBK_ONECLICK_API}/users/${userId}/transactions`, data)
        .then((response) => resolve(Vue._.get(response, "data")))
        .catch((err) => reject(err));
    });
  },
};
